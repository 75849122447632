@import './editor.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.top-100 {top: 100%}
.bottom-100 {bottom: 100%}
.max-h-select {
    max-height: 300px;
}


.ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}





@keyframes ping {
    75%, 100% {
        transform: scale(2);
        opacity: 0;
    }
}



.pingIcon {
    animation: pingIcon 5s  infinite;
}



@keyframes pingIcon {

    50%, 75% {
        transform: scale(120%);
        opacity: .9;
        color: rgb(255,102,0);
    }
}


.animate-spin{
    animation: spin 2s infinite linear;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}